import React, { Component } from 'react';
import './App.css';
import "@material/checkbox/dist/mdc.checkbox.css";
import "@material/form-field/dist/mdc.form-field.css";
import MaterialCheckbox from './MaterialCheckbox';
import AcceptFriendButton from './AcceptFriendButton';

class FriendsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCheckboxes: !!props.checkedListener
        }
    }

    _renderFriend(friend) {
        return (
            <div key={friend.uid}>
                <div className="mdc-form-field">
                    <div className={"mdc-checkbox"}>
                        <input type="checkbox"
                            className="mdc-checkbox__native-control"
                            id={friend.uid}
                            value={friend.uid}
                            onChange={this.props.checkedListener} />
                        <MaterialCheckbox />
                    </div>
                    <label htmlFor={friend.uid}>{friend.name}</label>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="thick">
                {this.props.inProgressFriends && this.props.inProgressFriends.length ?
                    <div>
                        <h4>Pending Friends:</h4>
                        {this.props.inProgressFriends.map(inProgressFriend =>
                            <div className="thick" key={inProgressFriend.uid}>
                                <li className="inline thick">{inProgressFriend.name}</li>
                                {!inProgressFriend.state ?
                                    <AcceptFriendButton friendUid={inProgressFriend.uid} />
                                    :
                                    <span>Request sent</span>
                                }
                            </div>
                        )}
                    </div>
                    : undefined
                }
                <h4>Friends:</h4>
                {this.state.showCheckboxes ?
                    (this.props.friends.map(friend => this._renderFriend(friend)))
                    :
                    (this.props.friends.map(friend => <li key={friend.uid}>{friend.name}</li>))
                }
            </div>
        );
    }
}

export default FriendsList;