import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom"
import firebase, { auth, provider } from './firebase.js';
import FriendsList from './FriendsList.js'
import ItemList from './ItemList.js'
import AddFriend from './AddFriend.js'
import AddItemButton from './AddItemButton'
import NewItem from './NewItem.js'
import InviteFriends from './InviteFriends.js'
import EmailNotificationPreference from './EmailNotificationPreference.js'
import PushNotificationPreference from './PushNotificationPreference.js';
import PushNotificationsNotSupported from './PushNotificationsNotSupported.js';
import './App.css';
import MarketingPage from './MarketingPage.js';
import NewVersionNotification from './newVersionNotification/NewVersionNotification.js';

class App extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      user: null,
      friends: []
    }
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    firebase.firestore().settings({
      timestampsInSnapshots: true
    });
    if (firebase.messaging.isSupported()) {
      firebase.messaging().usePublicVapidKey("BKPyT_Gn5ib51J-rw9LpTSw1GjOnpMcsTfYTu__kzFjlnz4P4_T6YgIQ9umD5OCWPRYRFUDkqiuYJHvc7Sokh5I");
    }
  }

  resolveUserName(userId, state) {
    return firebase.firestore()
      .collection("users")
      .doc(userId)
      .get()
      .then(user => {
        if (user.exists) {
          return {
            name: user.data().name,
            state,
            uid: user.id
          }
        } else {
          return {};
        }
      })
      .catch(error => {
        console.log(`Error getting user name for ${userId}: ` + error)
        return {};
      });
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
        this.initializeDocuments();
        firebase.firestore().collection("list").doc(firebase.auth().currentUser.uid).collection("friend").onSnapshot(friends => {
          Promise.all(friends.docs.map(friend => this.resolveUserName(friend.id, friend.data().state)))
            .then(resolvedFriends => this.setState({ friends: resolvedFriends }))
        }
          , error => {
            console.log("error getting friends list snapshot: ", error);
          });
      }
    });
  }

  logout() {
    auth.signOut()
      .then(() => {
        this.setState({
          user: null
        });
      });
  }

  login() {
    auth.signInWithPopup(provider)
      .then((result) => {
        const user = result.user;
        this.setState({
          user
        });
        window.fbq('track', 'CompleteRegistration');
      });
  }

  initializeDocuments() {
    const db = firebase.firestore();
    db.collection("users").doc(firebase.auth().currentUser.uid).set({
      name: firebase.auth().currentUser.displayName || "User",
      email: firebase.auth().currentUser.email
    }, { merge: true })
      .then(() => console.log("User exists"))
      .catch((error) => console.log("Error creating user " + error + " User may already exist"))
  }

  _completedFriends() {
    return this.state.friends.filter(value => value.state === "completed");
  }

  _inProgressFriends() {
    return this.state.friends.filter(value => value.state !== "completed");
  }

  _friendsPlusYourself() {
    return this.state.friends.concat([{
      name: firebase.auth().currentUser.displayName + " (Yourself)",
      state: "completed",
      uid: firebase.auth().currentUser.uid
    }]);
  }

  render() {
    return (
      <div className="App">
        <div className="wrapper">
          {this.state.user ?
            <div>
              <header className="mdl-color-text--white mdl-color--light-blue-700">
                <div id="user-container">
                  <div id="user-name">{firebase.auth().currentUser.displayName}</div>
                  <button className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-color-text--white" onClick={this.logout}>Log Out</button>
                </div>
              </header>
              <NewVersionNotification />
              <Router>
                <div className="webapp">
                  <Route exact path="(/|/index.html)" render={props => firebase.messaging.isSupported() ? <PushNotificationPreference /> : <PushNotificationsNotSupported />} />
                  <Route exact path="(/|/index.html)" component={EmailNotificationPreference} />
                  <Route exact path="(/|/index.html)" component={InviteFriends} />
                  <Route exact path="(/|/index.html)" render={(props) => <FriendsList friends={this._completedFriends()} inProgressFriends={this._inProgressFriends()} />} />
                  <Route exact path="(/|/index.html)" component={ItemList} />
                  <Route exact path="(/|/index.html)" component={AddItemButton} />
                  <Route exact path="/add-friend" component={AddFriend} />
                  <Route exact path="/new-item" render={() => <NewItem friends={this._friendsPlusYourself()} />} />
                </div>
              </Router>
            </div>
            :
            <MarketingPage login={this.login} />

          }
        </div>
      </div>
    );
  }
}

export default App;
