import firebase from 'firebase'
const config = {
    "apiKey": "AIzaSyCd-p2RFwxvU9dyATBz6SM-PDtrRLt-I5k",
    "databaseURL": "https://linq-bd9d1.firebaseio.com",
    "storageBucket": "linq-bd9d1.appspot.com",
    "authDomain": "getpigeon.app",
    "messagingSenderId": "60217473688",
    "projectId": "linq-bd9d1"
  }
firebase.initializeApp(config);
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export default firebase;