import React, { Component } from 'react';
import firebase from './firebase.js';
import './App.css';
import "@material/checkbox/dist/mdc.checkbox.css";
import "@material/form-field/dist/mdc.form-field.css";
import QueueItem from './QueueItem.js';

class ItemList extends Component {
    constructor() {
        super();
        this.state = {
            items: []
        }
        this.onItemChecked = this.onItemChecked.bind(this)
    }

    componentDidMount() {
        this.fetchList();
    }

    fetchList() {
        firebase.firestore()
            .collection("list")
            .doc(firebase.auth().currentUser.uid)
            .collection("item")
            .onSnapshot(
                doc => {
                    let newItems = [];
                    doc.forEach(item => {
                        newItems.push({
                            id: item.id,
                            display: {
                                addedBy: "",
                                creationTime: item.data().creationTime ? item.data().creationTime.toDate().toLocaleString() : "a long time ago"
                            },
                            item: {
                                text: item.data().text || "An item",
                                completed: item.data().completed || false,
                                creationTime: item.data().creationTime || firebase.firestore.Timestamp.now(),
                                addedBy: item.data().addedBy || "A friend"
                            }
                        });
                    });
                    console.log("item collection update")
                    const jobs = newItems.map(item => firebase.firestore()
                        .collection("users")
                        .doc(item.item.addedBy)
                        .get()
                        .catch(error => {
                            console.log("Error getting user name for item list: " + error);
                            Promise.resolve();
                        }));
                    Promise.all(jobs)
                        .then(results => {
                            results.forEach((resolvedName, index) => {
                                if (resolvedName && resolvedName.exists) {
                                    newItems[index].display.addedBy = resolvedName.data().name;
                                }
                            });
                            this.setState({ items: newItems.sort((a, b) => a.item.creationTime.toMillis() - b.item.creationTime.toMillis()) });
                        });
                }, error => {
                    console.log("error getting item list: ", error);
                });
    }

    onItemChecked(e) {
        let changedItem = this.state.items.find(item => item.id === e.target.value).item;
        changedItem.completed = !changedItem.completed;
        firebase.firestore()
            .collection("list")
            .doc(firebase.auth().currentUser.uid)
            .collection("item")
            .doc(e.target.value)
            .set(changedItem)
            .catch(error => console.log("Error changing completed state: " + error));
    }

    makeUncompletedList() {
        return this.makeList(false)
    }

    makeCompletedList() {
        return this.makeList(true)
    }

    makeList(itemState) {
        return (
            this.state.items.map(item =>
                !!item.item.completed === itemState ?
                    <div key={item.id}>
                        <QueueItem
                            id={item.id}
                            display={item.display}
                            item={item.item}
                            onItemChecked={this.onItemChecked}
                        />
                    </div>
                    : undefined
            )
        )
    }

    render() {
        return (
            <div className="thick">
                <h4>Your list:</h4>
                {this.makeUncompletedList()}
                <h4>Completed items:</h4>
                {this.makeCompletedList()}
            </div>
        )
    }
}

export default ItemList;