import React, { Component } from 'react';
import MaterialCheckbox from './MaterialCheckbox.js';
import firebase from './firebase.js';
import Linkify from 'react-linkify';

class QueueItem extends Component {

    constructor(props) {
        super(props)
        this.onDeleteClicked = this.onDeleteClicked.bind(this)
    }

    onDeleteClicked(e) {
        firebase.firestore()
            .collection("list")
            .doc(firebase.auth().currentUser.uid)
            .collection("item")
            .doc(e.currentTarget.value)
            .delete()
            .catch(error => console.log("Error changing completed state: " + error));
    }

    render() {
        return (
            <div className="mdc-form-field">
                <div className="mdc-checkbox">
                    <input type="checkbox" checked={this.props.item.completed ? "checked" : ""}
                        className="mdc-checkbox__native-control"
                        value={this.props.id}
                        id={this.props.id}
                        onChange={this.props.onItemChecked} />
                    <MaterialCheckbox />
                </div>
                <label className={(this.props.item.completed ? "strikethrough " : "") + "align-left"} htmlFor={this.props.id}>
                    <Linkify properties={{target: '_blank'}}>
                        {this.props.item.text}
                    </Linkify>
                    <span className="block">
                        {"Sent by: " + this.props.display.addedBy + " on " + this.props.display.creationTime}
                    </span>
                </label>
                <button onClick={this.onDeleteClicked} value={this.props.id} className="thick mdl-button mdl-js-button mdl-js-ripple-effect">
                    <i className="material-icons">delete</i>
                </button>
            </div>
        );
    }
}

export default QueueItem;