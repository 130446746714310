import React, { Component } from 'react';
import './App.css'
import { auth } from './firebase.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './InviteFriends.css'
import '@material/react-button/dist/button.css';
import Button from '@material/react-button/dist';

class InviteFriends extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inviteUrl: (process.env.NODE_ENV === 'production' ? 'https://getpigeon.app' : 'http://localhost:3000')
                + `/add-friend#uid=${auth.currentUser.uid}`,
            copied: false
        }
    }

    render() {
        return (
            <div className="thick">
                <h4>Send this link to friends so they can add items to your list:</h4>
                <input className="thick" readOnly type="text" value={this.state.inviteUrl} ></input>
                <CopyToClipboard text={this.state.inviteUrl} onCopy={(text, result) => this.setState({ copied: result })}>
                    <Button outlined>Copy link</Button>
                </CopyToClipboard>
                {this.state.copied ? <span className="fade-in greentext thick">Copied!</span> : ""}
            </div>
        );
    }
}

export default InviteFriends;