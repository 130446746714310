import React, { Component } from 'react';
import Button from '@material/react-button/dist';
import firebase from './firebase';
import alerter from './utils/alerter.js';

class AcceptFriendButton extends Component {

    constructor(props) {
        super(props)
        this.acceptFriend = this.acceptFriend.bind(this);
    }

    acceptFriend(e) {
        firebase.firestore()
            .collection("list")
            .doc(firebase.auth().currentUser.uid)
            .collection("friend")
            .doc(this.props.friendUid)
            .update({
                state: "completed"
            })
            .catch(error => alerter("Error accepting friend request " + error))
    }

    render() {
        return (
            <Button outlined onClick={this.acceptFriend}>Accept Request</Button>
        )
    }
}

export default AcceptFriendButton;