import firebase from './firebase.js';

class PushNotificationService {

    uploadLatestToken() {
        firebase.messaging().getToken().then((refreshedToken) => {
            if (refreshedToken) {
                console.log('Token refreshed.');
                this.sendTokenToServer(refreshedToken);
            } else {
                console.log("No push token for this device");
            }
        }).catch((err) => {
            console.log('Unable to retrieve refreshed token ', err);
        });
    }

    monitorTokenRefresh() {
        this.unsubscribeFromTokenRefresh = firebase.messaging().onTokenRefresh(() => {
            this.uploadLatestToken();
        });
    }

    removeMonitorTokenRefresh() {
        this.unsubscribeFromTokenRefresh();
    }

    sendTokenToServer(currentToken) {
        console.log('Sending token to server...');
        return firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid)
            .update({
                pushToken: currentToken
            }).catch(error => console.log("Error sending token to server: " + error))
    }

    subscribeUser() {
        return firebase.messaging().requestPermission().then(() => {
            console.log('Notification permission granted.');
            return this.uploadLatestToken();
        });
    }
}

export default PushNotificationService;
