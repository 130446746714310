import React, { Component } from 'react';
import firebase from './firebase.js';
import MaterialCheckbox from './MaterialCheckbox.js';

class EmailNotificationPreference extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailNotifications: false
        }
        this.onItemChecked = this.onItemChecked.bind(this);
    }

    componentDidMount() {
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid)
            .onSnapshot(doc => {
                if (doc.exists) {
                    this.setState({ emailNotifications: doc.data().emailNotifications });
                } else {
                    console.log("Couldn't find user for email preferences");
                }
            }, error => console.log("Error getting user for email preferences: " + error));
    }

    onItemChecked(e) {
        let checked = e.target.checked;
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid)
            .update({
                emailNotifications: checked
            })
            .then(() => console.log("Email preferences updated"))
            .catch(error => console.log("Error updating email preferences: " + error));
    }

    render() {
        return (
            <div className="mdc-form-field margin-top margin-right">
                <div className="mdc-checkbox">
                    <input type="checkbox" checked={this.state.emailNotifications ? "checked" : ""}
                        className="mdc-checkbox__native-control"
                        // value={item.id}
                        id="emailNotifications"
                        onChange={this.onItemChecked} />
                    <MaterialCheckbox />
                </div>
                <label htmlFor="emailNotifications">Email notifications</label>
            </div>
        )
    }
}

export default EmailNotificationPreference;