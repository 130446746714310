import React, { Component } from 'react';
import firebase from './firebase';
import './App.css'
import queryString from 'query-string'
import InviteFriends from './InviteFriends';
import alerter from './utils/alerter.js';

class AddFriend extends Component {
    constructor() {
        super();
        this.state = {
            newFriendId: null
        }
    }

    componentDidMount() {
        const parsedHash = queryString.parse(window.location.hash);
        if (parsedHash.uid) {
            firebase.firestore()
                .batch()
                .set(
                    firebase.firestore()
                        .collection("list")
                        .doc(firebase.auth().currentUser.uid)
                        .collection("friend")
                        .doc(parsedHash.uid),
                    {
                        state: "pending"
                    })
                .set(
                    firebase.firestore()
                        .collection("list")
                        .doc(parsedHash.uid)
                        .collection("friend")
                        .doc(firebase.auth().currentUser.uid),
                    {}
                )
                .commit()
                .then(() => {
                    return firebase.firestore()
                        .collection("users")
                        .doc(parsedHash.uid)
                        .get();
                })
                .then((doc) => {
                    if (doc.exists) {
                        this.setState({ newFriendId: doc.data().name });
                    } else {
                        console.log("Friend name document doesn't exist");
                    }
                })
                .catch(error => console.log("Error adding friend: " + error))
        } else {
            alerter(`Error: malformed friend request`);
        }
    }

    render() {
        return (
            <div>
                {this.state.newFriendId ?
                    <div className="thick">
                        <h4>Friend request sent to {this.state.newFriendId}!</h4>
                        <h5>Once {this.state.newFriendId} has accepted, you can add items to each other's lists!</h5>
                    </div>
                    :
                    <div>
                        You should only be here if you clicked on a friend's link!
                    </div>
                }
                <InviteFriends />
                <a href="/">Return to Main Page</a>
            </div>
        );
    }
}

export default AddFriend;