import React, { Component } from 'react';
import firebase from './firebase.js';
import MaterialCheckbox from './MaterialCheckbox.js';
import PushNotificationService from './PushNotificationService.js';

class PushNotificationPreference extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pushNotifications: false
        }
        this.onItemChecked = this.onItemChecked.bind(this);
        this.pushNotificationService = new PushNotificationService();
    }

    componentDidMount() {
        this.pushNotificationService.uploadLatestToken();
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid)
            .onSnapshot(doc => {
                if (doc.exists) {
                    this.setState({ pushNotifications: doc.data().pushNotifications });
                } else {
                    console.log("Couldn't find user for push preferences");
                }
            }, error => console.log("Error getting user for push preferences: " + error));
        this.pushNotificationService.monitorTokenRefresh();
    }

    componentWillUnmount() {
        this.pushNotificationService.removeMonitorTokenRefresh();
    }

    onItemChecked(e) {
        let checked = e.target.checked;
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid)
            .update({
                pushNotifications: checked
            })
            .then(() => {
                console.log("Push preferences updated")
                if (checked) {
                    return this.pushNotificationService.subscribeUser();
                } else {
                    return Promise.resolve();
                }
            })
            .catch(error => console.log("Push preferences error: " + error));
    }

    render() {
        return (
            <div className="mdc-form-field margin-top margin-right">
                <div className="mdc-checkbox">
                    <input type="checkbox" checked={this.state.pushNotifications ? "checked" : ""}
                        className="mdc-checkbox__native-control"
                        id="pushNotifications"
                        onChange={this.onItemChecked} />
                    <MaterialCheckbox />
                </div>
                <label htmlFor="pushNotifications">Push notifications</label>
            </div>
        )
    }
}

export default PushNotificationPreference;