import React, { Component } from 'react';
import LandingPageBehaviors from './LandingPageBehaviors';

import "./css/bootstrap.min.css";
import "./css/all.min.css";
import "./css/simple-line-icons.css";
import "./css/new-age.css";

class MarketingPage extends Component {

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
                    <div className="container">
                        <a className="navbar-brand js-scroll-trigger" href="#page-top">Pigeon</a>
                        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                            Menu
                            <i className="fas fa-bars"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link" onClick={this.props.login}>Log In</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link js-scroll-trigger" href="#features">Why Pigeon?</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>


                <header className="masthead">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-lg-7 my-auto">
                                <div className="header-content mx-auto">
                                    <h1 className="">Guarantee that your whole team gets the message</h1>
                                    <hr />
                                    <div className="mb-5 section-heading">
                                        <h2>Send a task to each member of your team.</h2>
                                        <h2>Get notified when each person crosses it off their list.</h2>
                                    </div>
                                    <a onClick={this.props.login} className="btn btn-outline btn-xl js-scroll-trigger">Get started</a>
                                </div>
                            </div>
                            <div className="col-lg-5 my-auto">
                                <div className="device-container">
                                    <div className="device-mockup iphone6_plus portrait white">
                                        <div className="device">
                                            <div className="screen">
                                                <img src="img/linqueue4.png" className="img-fluid good-border" alt="Sending an item to multiple people" title="Sending an item to multiple people" />
                                            </div>
                                            <div className="button">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <section className="features" id="features">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 my-auto">
                                <div className="device-container">
                                    <div className="device-mockup iphone6_plus portrait white">
                                        <div className="device">
                                            <div className="screen">

                                                <img src="img/linqueue2.png" className="img-fluid good-border" alt="The main screen of the app" title="The main screen of the app" />
                                            </div>
                                            <div className="button">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 my-auto">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="feature-item">
                                                <i className="icon-drawer text-primary"></i>
                                                <h3>Better than noisy group conversations</h3>
                                                <p className="text-muted">When a conversation leads to an action item, queue it. Don't lose it in the conversation</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="feature-item">
                                                <i className="icon-share text-primary"></i>
                                                <h3>Every team member gets their own list</h3>
                                                <p className="text-muted">Easily send the same item to many people at once. They each get their own task, unlike Jira or Trello where everyone shares tasks</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="feature-item">
                                                <i className="icon-screen-smartphone text-primary"></i>
                                                <h3>Notifications</h3>
                                                <p className="text-muted">Get notified by email or push when someone adds to your list or when someone completes something you sent</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="feature-item">
                                                <i className="icon-list text-primary"></i>
                                                <h3>Simple &amp; Clean</h3>
                                                <p className="text-muted">Get started in 25 seconds</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cta">
                    <div className="cta-content">
                        <div className="container">
                            <h2>Keep your team in sync</h2>
                            <a onClick={this.props.login} className="btn btn-outline btn-xl js-scroll-trigger">Get Started!</a>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </section>

                <footer>
                    <div className="container">
                        <p>Made by <a href="https://twitter.com/theMacroChip" target="blank">Chip Thien</a>. All Rights Reserved.</p>
                    </div>
                </footer>

                <LandingPageBehaviors />

            </div>
        );
    }
}

export default MarketingPage;
