import React, { Component } from 'react';
import './App.css'

class AddItemButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.newItem = this.newItem.bind(this);
    }

    newItem() {
        this.props.history.push('/new-item')
    }

    render() {
        return (
            <button onClick={this.newItem} className="thick mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored">
                <i className="material-icons">add</i>
            </button>
        )
    }
}

export default AddItemButton;