import React, { Component } from 'react';
import './App.css'
import FriendsList from './FriendsList';
import firebase from "./firebase.js";
import alerter from './utils/alerter.js';

class NewItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            receivers: [],
            text: ""
        }
        this.newItem = this.newItem.bind(this)
        this.onFriendChecked = this.onFriendChecked.bind(this)
        this.onTextChange = this.onTextChange.bind(this)
    }

    newItem() {
        let item = this.state.text;
        let addOperations = [];
        if (!item.trim()) {
            alerter("Please enter a message to send.");
        } else if (!this.state.receivers.length) {
            alerter("Please select one or more recipients.");
        } else {
            this.state.receivers.forEach(element => {
                addOperations.push(new Promise((res, rej) => firebase.firestore().collection("list")
                    .doc(element)
                    .collection("item")
                    .add({
                        text: item,
                        creationTime: firebase.firestore.Timestamp.now(),
                        addedBy: firebase.auth().currentUser.uid
                    })
                    .then(doc => {
                        res({
                            receiver: element,
                            docId: doc.id
                        })
                    })
                    .catch(error => {
                        res({
                            receiver: element,
                            error: error
                        })
                    })));
            });
            Promise.all(addOperations)
                .then(results => {
                    if (results.every(element => !element.error)) {
                        alerter("Success!");
                    } else {
                        let errors = [];
                        results.forEach(element => {
                            if (element.error) {
                                errors.push(element);
                            }
                        });
                        alerter("Error. This is either a bug or you have not both added each other as friends. Anyway, here's the error: " + JSON.stringify(errors));
                    }
                })
        }
    }

    onTextChange(e) {
        this.setState({ text: e.target.value })
    }

    onFriendChecked(event) {
        if (event.target.checked) {
            this.state.receivers.push(event.target.value)
        } else {
            this.state.receivers.splice(this.state.receivers.indexOf(event.target.value), 1)
        }
    }

    render() {
        return (
            <div>
                <h4>New Item:</h4>
                <textarea autoFocus={true} rows="5" cols="30" placeholder="Share something with friends" value={this.state.text} onChange={this.onTextChange}>
                </textarea>
                <FriendsList friends={this.props.friends} checkedListener={this.onFriendChecked} />
                <button onClick={this.newItem} className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored">
                    <i className="material-icons">send</i>
                </button>
            </div>
        )
    }
}

export default NewItem;